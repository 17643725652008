/*CSS for choosing reservations throughout the site*/
.res-type-container {
    margin-top: 10%;
}

.greeting-container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.map-manual-toggle-container {
    display: flex;
    justify-content: center;
    margin-top: 1%;
}

/* Invisible tabbing styles  */

.ghost-tab-container {
    position: relative;
    height: 20px;
    overflow-y: hidden;

    ul {
        position: absolute;
        left: -9999px;
    }

    li {
        list-style: none;
    }
}

@media only screen and (max-width: 992px) {
    .map-manual-toggle-container {
        margin-top: 0;
    }

}



@media only screen and (max-width: 769px) {
    .greeting-container {
        text-align: center;
    }

}