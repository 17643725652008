/* AllAvialableWorkstationForm.css (THis one), EditReservationForm, PodAndWorkstationDropdown*/
@import '../abstracts/variables.module';

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* AllAvialableWorkstationForm.css (THis one), AdminAvialableWorkstationForm.css, EditWorkstationView, ProcessReservation*/
.text-center {
  text-align: center;
  justify-content: center;
}

/* AddAdminForm, App.jsx, AdminRequestTable, AdminUniversalDatePicker, FilterUniversalDatePicker, UniversalDatePicker*/
.navtabs {
  &>a:hover{
    background-color: $concordLightGrey;
    
  }
  display: flex;
  justify-content: center;
  text-align: left;
}

// .navtabs-tab:hover {
//     background-color: #bada55;
// }