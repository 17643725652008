.cancelButton {
    background-color: #444546;
    margin-top: -10px;
    color: white;
    margin-right: 5px;
  }
  
  .cancelButton:hover {
    background-color: #525864;
  }
  
  .cancelButton:focus {
    background-color: #525864;
    outline: none;
  }
  
  .cancelButton.btn.btn-outline-secondary {
    background-color: #525864;
    border: #525864;
    color: white;
  }
  
  .cancelButton.btn.btn-outline-secondary:hover {
    background-color: #525864;
  }
  
  .cancelButton.btn.btn-outline-secondary:focus {
    background-color: #525864;
    outline: none;
  }