@import '../abstracts/variables.module';

/*THESE COMPONENTS ARE FOR THE FORM TO CREATE RESERVATIONS*/
/* Many of these universal*/

/* AddAdminForm Admin & AllAvailableWorkstationForm, Create & EditWorkstationForm*/

.reservation-form-inputs {
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
  // box-shadow: 2px 2px 4px rgb(0 0 0 / 25%);
  max-width: 1000px;
  margin: 0 auto 0 auto;
}

.reservation-form-inputs > * {
  padding-top: 10px;
}

.form-dates-and-search {
  display: flex;
  flex-direction: row;
  order: 1;
  margin: 0 auto;
}



@media only screen and (max-width: 991px) {
  .reservation-form-inputs{
    background-color: transparent;
    box-shadow: none;
  }
  .reservation-form-inputs > * {
    padding-top: 0px;
  }
  .form-dates-and-search {
    flex-direction: column;
    width: 75%;
  }
}



/* AddAdminForm Admin & AllAvailableWorkstationForm, Create & EditWorkstationForm*/
.form-title {
  margin-top: 24px;
  text-align: center;
}

/* AddAdminForm Admin & AllAvailableWorkstationForm, Create & EditWorkstationForm*/
.form-container {
  text-align: center;
  justify-content: center;
}

.homepage-name-container{
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-top: 10px;
}

.name-label {
  text-align: left;
  padding-top: 1rem;
}

/* AddAdminForm, AdminAllAvailableWorkstationForm*/
.email-label {
  text-align: left;
}

/* AddAdminForm AdminAvailableWorkstationForm*/
.name-container {
  display: flex;
  margin-top: 24px;
}

/* AddAdminForm AdminAvailableWorkstationForm*/
.fname-container {
  width: 100%;
}

/* AddAdminForm AdminAvailableWorkstationForm*/
.lname-container {
  margin-left: 24px;
  width: 100%;
}

/* AddAdminForm AdminAvailableWorkstationForm*/
.email-container {
  margin-top: 24px;
}

/*AllAvailableWorkstationForm*/
.startdate-container-1 {
  margin-top: 24px;
  width: 300%;
}

/*AdminAllAvailableWorkstationForm*/
.startdate-container {
  margin-top: 24px;
}

/*AllAvailableWorkstationForm*/
.enddate-container-1 {
  margin-top: 24px;
  width: 300%;
}

/*AdminAllAvailableWorkstationForm*/
.enddate-container {
  margin-top: 24px;
  margin-bottom: 24px;
}

/* Admin/AllAvailableWorkstationForm */
.pod-workstation-container {
  display: flex;
  justify-content: center;
}

.admin-form {
  display: flex;
  justify-content: center;
  //margin-top: 1%;
  margin-bottom: 1%;
}
