@import "../base/typography";
@import "../abstracts/variables.module";

/* In NavbarPackage.js */
.logo {
  height: auto;
  width: auto;
  max-height: 50px;
  max-width: 155px;
  margin-right: 10px;
}

/* In NavbarPackage.js */
.navbar-brand-two {
  color: $concordBlue;
  text-decoration: none;
}

/* In NavbarPackage.js */
.logo-div {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding-top: 1%;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 50px 0px 50px;
}

/* In NavbarPackage.js */
.logo-navbar {
  font-family: "Calibre";
  font-size: auto;
  display: grid;
  padding-top: 10px;
}
