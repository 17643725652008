// Colors 
$concordRed: #ff4938;
$concordLightRed: hsla(5, 100%, 61%, 0.397);
$concordDarkRed: #d32328;

$concordYellow: #FFC000;
$concordDarkYellow: #d49f00;
$concordBlue: #05252C;
$concordLightBlue: #375054;
$concordGrey: #97989b;
$concordLightGrey: rgba(151, 152, 155, 0.502);

// Breakpoint map
$breakpoints: (
    'tiny': (min-width: 320px),
    'small': (min-width: 481px),
    'medium': (min-width: 769px),
    'large': (min-width: 1025px),
    'huge': (min-width: 1201px)
);

:export {
    concordRed: $concordRed;
    concordDarkRed: $concordDarkRed;
    concordGrey: $concordGrey;
    concordYellow: $concordYellow;
    concordDarkYellow: $concordDarkYellow;
    concordBlue: $concordBlue;
    concordLightBlue: $concordLightBlue;
    concordGrey: $concordGrey;
    concordLightGrey: $concordLightGrey;
}

/** Hex code reference from 2022 Concord Brand Guidelines doc: 
*
* Grey (from dark to light)
* #96979B
* #ABABAA
* #BFBEBE
* #D1D1D0
* #E5E4E3
*
* Red
* #FF4838
* #FE6C5E
* #FE6C5E
* #FBB2AA
* #FBB2AA
*
* Blue
* #05252C
* #375054
* #66797D
* #96A4A6
* #C8CDCE
* 
* Yellow
* #FFBF00
* #FECC31
* #FDD762
* #FBE294
* #FBEBC5
* 
*/