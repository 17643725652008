@import '../abstracts/variables.module';

@for $i from 1 through 6 {
    h#{7 - $i} {
        font-size: $i * .4em;
    }
}

@font-face {
    font-family: "CalibreThin";
    font-style: normal;
    font-weight: 100;
    src: url("../../fonts/CalibreThin.otf") format("opentype");
}
@font-face {
    font-family: "CalibreLight";
    font-style: normal;
    font-weight: 300;
    src: url("../../fonts/CalibreLight.otf") format("opentype");
}
@font-face {
    font-family: "Calibre";
    font-style: normal;
    font-weight: 400;
    src: url("../../fonts/CalibreRegular.otf") format("opentype");
  }
@font-face {
    font-family: "CalibreMedium";
    font-style: normal;
    font-weight: 500;
    src: url("../../fonts/CalibreMedium.otf") format("opentype");
}
@font-face {
    font-family: "CalibreSemibold";
    font-style: normal;
    font-weight: 600;
    src: url("../../fonts/CalibreSemibold.otf") format("opentype");
}
@font-face {
    font-family: "CalibreBold";
    font-style: normal;
    font-weight: 700;
    src: url("../../fonts/CalibreBold.otf") format("opentype");
}
@font-face {
    font-family: "CalibreBlack";
    font-style: normal;
    font-weight: 900;
    src: url("../../fonts/CalibreBlack.otf") format("opentype");
}
@font-face {
    font-family: "CalibreLightItalic";
    font-style: italic;
    font-weight: 100;
    src: url("../../fonts/CalibreLightItalic.otf") format("opentype");
}
@font-face {
    font-family: "CalibreItalic";
    font-style: italic;
    font-weight: 400;
    src: url("../../fonts/CalibreRegularItalic.otf") format("opentype");
}
@font-face {
    font-family: "CalibreMediumItalic";
    font-style: italic;
    font-weight: 500;
    src: url("../../fonts/CalibreMediumItalic.otf") format("opentype");
}
@font-face {
    font-family: "CalibreSemiboldItalic";
    font-style: italic;
    font-weight: 600;
    src: url("../../fonts/CalibreSemiboldItalic.otf") format("opentype");
}
@font-face {
    font-family: "CalibreBoldItalic";
    font-style: italic;
    font-weight: 700;
    src: url("../../fonts/CalibreBoldItalic.otf") format("opentype");
}
@font-face {
    font-family: "CalibreBlackItalic";
    font-style: italic;
    font-weight: 900;
    src: url("../../fonts/CalibreBlackItalic.otf") format("opentype");
}
