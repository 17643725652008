/*ReservationConflictingModal **conflicting is also spelled wrong*/
@import "../abstracts/variables.module";

#confling-modal-header {
    color: white;
}
  
.hide-confling-model-header {
    background-color: $concordRed;
}

.overwrite-warning-text {
    color: $concordRed; 
}

.reservation-text-container {
    
    
    align-items: vertical;
}

#individual-reservation {
    background-color: #f2f2f2;
    border-radius: 3px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer-button-container {
    float: right;
}