@import "../abstracts/mixins";

.issue-type-dropdown {
    margin-left: auto;
    margin-right: auto;
}

.issue-type-dropdown > * {
    width: 200px;
}

#save-button {
    margin-left: auto;
    margin-right: auto;
}

.modal-min-height-control {
    min-height: 25vh !important;
}

.modal-height-control {
    min-height: 70vh !important;
}

.column-lg-6 {
    max-width: 100% !important;
}

.wkstn-textarea {
    width: 80%;
    margin: 0 auto;
    // @include respond-to("small") {
    //     width: 100%;
    // }
    // @include respond-to("medium") {
    //     width: 100%;
    // }
}
