/* EditReservationForm */
@import '../abstracts/variables.module';


#display-pods {
  .wkstn-btn {
    margin-left: auto;
    margin-right: auto;
  }
}

#dropdown-pod-menu{
  margin-left: auto;
  margin-right: auto;
}

#dropdown-basic.wkstn-btn.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 50px;
  width: 200px;
  color: white;
  border: #9f3236;
}

#dropdown-basic.wkstn-btn.dropdown-toggle.btn.btn-primary:hover {
  background-color: #d32328;
  border: #9f3236;
}

#dropdown-basic.wkstn-btn.dropdown-toggle.btn.btn-primary:focus {
  border: #9f3236;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

#dropdown-basic.wkstn-btn.dropdown-toggle.btn.btn-primary:active {
  border: #9f3236;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

#filter-dropdown-btn.dropdown-toggle.btn.btn-primary {
  background-color: white;
  color: rgb(73, 80, 87);
  border-color: rgb(206, 212, 218);
  float: left;
  margin-right: 5px;
  width: 100%;
}

/* IN podAndWorkstationDropDown and AllAvailableWorkstationForm.css */
/*modified dropdown to be smaller for filter*/
#dropdown-pod-menu-filter.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 40px;
  width: 160px;
  color: white;
}

#dropdown-pod-menu-filter.dropdown-toggle.btn.btn-primary:hover {
  border: #9f3236;
}

#dropdown-pod-menu-filter.dropdown-toggle.btn.btn-primary:active {
  border: #9f3236;
  outline: none;
}

#dropdown-workstation-menu-filter.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 40px;
  width: 160px;
  color: white;
}

#dropdown-workstation-menu-filter.dropdown-toggle.btn.btn-primary:hover {
  border: #9f3236;
}

#dropdown-workstation-menu-filter.dropdown-toggle.btn.btn-primary:active {
  border: #9f3236;
  outline: none;
}

#dropdown-basic-filter.wkstn-btn.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 40px;
  width: 160px;
  color: white;
}

#dropdown-basic-filter.wkstn-btn.dropdown-toggle.btn.btn-primary:hover {
  border: #9f3236;
}

#dropdown-basic-filter.wkstn-btn.dropdown-toggle.btn.btn-primary:focus {
  border: #9f3236;
  outline: none;
}

/* PodAndWorkstationDropdown */
.drop-down-list.dropdown-item {
  background-color: white;
  color: black;
}

.drop-down-list.dropdown-item:focus {
  background-color: #96979b;
  color: white;
}

/* PodAndWorkstationDropdown */

#dropdown-pod-menu.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 50px;
  width: 200px;
  color: white;
  border: #9f3236;
}

#dropdown-pod-menu.dropdown-toggle.btn.btn-primary:hover {
  background-color: #d32328;
  border: #9f3236;
}

#dropdown-pod-menu.dropdown-toggle.btn.btn-primary:focus {
  border: #9f3236;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

/* PodAndWorkstationDropdown */
#dropdown-workstation-menu.dropdown-toggle.btn.btn-primary {
  background-color: $concordRed;
  border-radius: 7px;
  height: 50px;
  width: 200px;
  color: white;
  border: #9f3236;
}

#dropdown-workstation-menu.dropdown-toggle.btn.btn-primary:hover {
  background-color: #d32328;
  border: #9f3236;
}

#dropdown-workstation-menu.dropdown-toggle.btn.btn-primary:focus {
  border: #9f3236;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.dropdown-menu {
  width: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  
  input{
    max-width: 90%;
    margin:0 auto !important;
  }
}

.search-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 230px;
  
  &__caretdown {
    font-size: 10px;
    margin-left:15px;
    
  }
}