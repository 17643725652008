@import '../abstracts/variables.module';
@import '../abstracts/mixins';


/* Base primary/secondary buttons*/
.primary-btn {
  background-color: $concordRed;
  border: none;
  color: white;
  margin-right: 5px;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.primary-btn:disabled {
  opacity: 50%;
}

/** Alternative color to primary-btn */
.action-btn{
  background-color: $concordLightBlue;
  border: none;
  color: white;
  margin-right: 5px;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.action-btn:disabled {
    opacity: 50%;
}

.primary-btn:hover {
  background-color: $concordDarkRed;
}

.action-btn:hover {
  background-color: $concordBlue;
  color: white;
}

.primary-btn:active {
  background-color: $concordDarkRed;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.secondary-btn {
  background-color: #DBDBDB;
  border: 1px solid #DBDBDB;
  color: black;
  margin-right: 5px;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.secondary-btn:hover {
  background-color: #6c757d;
  color: white;
}

.secondary-btn:active {
  background-color: #6c757d;
  color: white;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.btn.primary-btn {
  background-color: #DBDBDB;
  border: 1px solid #DBDBDB;
  color: white;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.disabled-btn {
  background-color: $concordRed;
  border: 1px solid $concordRed;
  opacity: 45%;
  color: white;
  margin-right: 5px;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Aditional classes for primary/secondary buttons */
.wkstn-btn {
  margin: 10px;
  height: 50px;
  width: 200px;
  line-height: 50px;
}

.issue-btn{
  height: 50px;
  width: 200px;
}

.modal-btn {
  height: 40px;
  width: 90px;
}

.res-type {
  flex-wrap: wrap;
  width: 190px;
  height: 35px;
  line-height: 35px;
  margin-right: 0px;
  border-radius: 7px 0px 0px 7px;
}
.res-duration-buttons {
  order: 1;
  margin: 8px 0;
}

@media only screen and (max-width: 991px) {
  .res-type{
    border-radius: 7px;
    margin: 1px;
  }
  
}
.res-type.primary-btn:hover{
  cursor: default;
  background-color: $concordRed;
}
.res-type-right {
  width: 190px;
  height: 35px;
  margin-right: 0px;
  border-radius: 0px 7px 7px 0px;
  line-height: 35px;
}
@media only screen and (max-width: 991px) {
  .res-type-right{
    border-radius: 7px;
    margin: 1px;
  }
  
}
.res-type-right.primary-btn:hover {
  cursor: default;
  background-color: $concordRed;
}
.edit-type {
  width: 90px;
  height: 40px;
  margin-right: 0px;
  border-radius: 7px 0px 0px 7px;
}

.edit-type-right {
  width: 90px;
  height: 40px;
  margin-right: 0px;
  border-radius: 0px 7px 7px 0px;
}

.edit-type-outline {
  border-color: black;

}

.zoom-parent{
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1vh;
}

.zoom-btn {
  background-color:#ffffff;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  height: 35px;
  width: 35px;
  border-width: 2px;
  border-radius: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-btn {
  height: 35px;
  width: 70px;
  line-height: 35px;
}

.grid-add-wkstn-btn {
  height: 40px;
  line-height: 40px;
  width: 160px;
}
.grid-delete-wkstn-btn {
  height: 40px;
  width: 160px;
  border-color: black;
}

.grid-save-wkstn-btn {
  height: 40px;
  width: 160px;
  line-height: 40px;
  margin-left: auto;
}

.filter-cntrl-btn {
  margin-top: 25px;
  height: 35px;
  width: 70px;
  line-height: 35px;
}

.edit-wkstn-btn {
  height: 40px;
  width: 90px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 40px;
}

.btn-secondary.delete-wkstn-btn {
  height: 40px;
  width: 90px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: black;
  text-align: center;
  border-radius: 7px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-secondary.delete-wkstn-btn:hover {
  background-color: #6c757d;
  border: #6c757d;
  color: white;
}

.btn-secondary.delete-wkstn-btn:active {
  background-color: #6c757d;
  border: #6c757d;
  color: white;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

.btn-secondary.delete-wkstn-btn:focus {
  background-color: #cccccc;
  border: #cccccc;
  color: black;
  box-shadow: 0 0 0 0;
}

/* Other/old buttons */
.black-btn {
  margin: 4px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #05252c;
  border: #05252c;
  color: white;
  border-radius: 5px;
}

.black-btn:hover {
  background-color: #96979b;
}

.black-btn:focus {
  background-color: #96979b;
  outline: none;
}

.black-btn.btn.btn-secondary {
  margin: 4px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #05252c;
  border: #05252c;
  color: white;
  border-radius: 5px;
}

.black-btn.btn.btn-secondary:hover {
  background-color: #444546;
}

.black-btn.btn.btn-secondary:focus {
  background-color: #444546;
  outline: none;
}

#sign-in-btn {
  position: fixed;
  top: 50%;
  width: 10%;
  left: 45%;
  text-align: center;
  background-color: $concordRed;
  border-radius: 7px;
}

#close-btn.close-btn.btn.btn-primary {
  margin: 8px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #96979b;
  border: #96979b;
  color: white;
  border-radius: 7px;
}

#close-btn.close-btn.btn.btn-primary:hover {
  background-color: #b5b6ba;
}

#close-btn.close-btn.btn.btn-primary:focus {
  background-color: #b5b6ba;
  outline: none;
}

.close-btn {
  margin: 8px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #b2b7bd;
  border: #96979b;
  color: white;
  border-radius: 7px;
}

.close-btn:hover {
  /* Light grey
    */
  background-color: #b5b6ba;
}

.close-btn:focus {
  background-color: #b5b6ba;
  outline: none;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @include respond-to('small', 'medium', 'large') {
      margin-left: auto;
      margin-right: auto;
      flex-direction: row;
  }
}

.buttonContainer-2 {
  display: flex;
  justify-content: center;
  margin-left: -15%;
}

.buttonContainer-mobile {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

@media (max-width: 640px) {
  .buttonContainer-2 {
    display: flex;
    justify-content: center;
    margin-left:0%;
  }
}

button.btn.btn-link {
  color: #525864;
}

button.btn.btn-link:hover {
  font-weight: bold;
  border: none;
}

button.btn-link {
  font-weight: none;
  border: none;
  text-decoration: none;
}

button.btn-link:focus {
  font-weight: bold;
  border: none;
  text-decoration: none;
}

button.btn-link:hover {
  font-weight: none;
  border: none;
  text-decoration: none;
}

/* Used in Consolidated View */

/* .deleteButton.btn.btn-outline-secondary {
  background-color: $concordRed;
  border: #9f3236;
  color: white;
}

.deleteButton.btn.btn-outline-secondary:hover {
  background-color: #d32328;
}

.deleteButton.btn.btn-outline-secondary:focus {
  background-color: #d32328;
  outline: none;
}

.deleteButton.btn.btn-primary {
  background-color: $concordRed;
  border: #9f3236;
  color: white;
}

.deleteButton.btn.btn-primary:hover {
  background-color: #d32328;
}

.deleteButton.btn.btn-primary:focus {
  background-color: #d32328;
  outline: none;
} */

.edit-reservation-btn {
  display: flex;
  background-color: #cccccc;
  /* margin-top: -5px; */
  /* margin-bottom: -5px; */
  border: 1px solid black;
  color: white;
  margin-right: 5px;
}

.edit-reservation-btn:hover {
  background-color: #b2b7bd;
}

.edit-reservation-btn:focus {
  background-color: #d32328;
  outline: none;
}

.edit-reservation-btn.btn.btn-outline-secondary {
  background-color: #cccccc;
  border: 1px solid #525864;
  color: #525864;
  width: 70px;
}

.edit-reservation-btn.btn.btn-outline-secondary:hover {
  background-color: #525864;
  color: white;
}

.edit-reservation-btn.btn.btn-outline-secondary:focus {
  background-color: #d32328;
  outline: none;
}

/*styling for admin requests in ~admin settings tab?~*/
/*reject admin requests in rejectadminrequestbutton.js*/
.rejectButton {
  display: flex;
  background-color: white;
  color: #6c757d;
  outline: #6c757d;
  margin-right: 5px;
}

.rejectButton:hover {
  background-color: #6c757d;
}

.rejectButton:focus {
  background-color: white;
  outline: #6c757d;
}

.rejectButton.btn.btn-outline-secondary:focus {
  background-color: white;
  outline: #b2b7bd;
}

/* Used in EditWorkstationView */
.edit-btn {
  margin: 10px;
  border: #9f3236;
  color: white;
  border-radius: 7px;
  height: 40px;
  width: 75px;
  text-align: center;
}

.edit-btn:hover {
  /* Dark grey
    */
  background-color: #96979b;
}

.edit-btn:focus {
  background-color: #96979b;
  outline: none;
}

.edit-btn.btn.btn-secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  background-color: $concordRed;
  border: #9f3236;
  color: white;
  border-radius: 7px;
  text-align: center;
}

.edit-btn.btn.btn-secondary:hover {
  background-color: #d32328;
}

.edit-btn.btn.btn-secondary:focus {
  background-color: #d32328;
  outline: none;
}

.edit-btn.btn.btn-tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  background-color: #cccccc;
  border: 1px solid #525864;
  color: #525864;
  border-radius: 7px;
  text-align: center;
}

.edit-btn.btn.btn-tertiary:hover {
  background-color: #525864;
  color: white;
}

.edit-btn.btn.btn-tertiary:focus {
  background-color: 525864;
  outline: none;
}

#reservation-search-button {
  padding: 7px 60px 3px 60px;
  margin-top: 10px;
}


#time-slot-btn.btn.full-day-primary {
  background-color: $concordBlue;
  border: 2px solid #6c757d;
  border-radius: 9px;
  color: white;
  display: flex;
  justify-content: center;
  width: 80vw;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#time-slot-btn.btn.full-day-primary:hover {
  background-color: $concordLightBlue;
  border: 2px solid #6c757d;
  color: black;
}

#time-slot-btn.btn.full-day-primary:active {
  background-color: $concordBlue;
  border: 2px solid #6c757d;
  color: white;
}

#time-slot-btn.btn.full-day-primary:focus {
  background-color: $concordBlue;
  border: 2px solid #6c757d;
  color: white;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}

#time-slot-btn.btn.full-day-secondary {
  background-color: white;
  border: 2px solid #6c757d;
  border-radius: 9px;
  color: black;
  width: 80vw;
  display: flex;
  justify-content: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (min-width: 1075px) and (max-width: 476px) {
  #time-slot-btn.btn.full-day-secondary {
    width: 24rem;
  }
  #time-slot-btn.btn.full-day-primary {
    width: 24rem;
  }
}

@media screen and (min-width: 476px) {
  #time-slot-btn.btn.full-day-secondary {
    max-width: 465px;
    width: 30vw;
    min-width: 24rem;
  }
  #time-slot-btn.btn.full-day-primary {
    max-width: 465px;
    width: 30vw;
    min-width: 24rem;
  }
}

#time-slot-btn.btn.full-day-secondary:disabled {
  border: 2px solid #8a96a1;
  color: #8a96a1;
}
#time-slot-btn.btn.full-day-secondary:disabled:hover {
  background-color: white;
  border: 2px solid #8a96a1;
  color: #8a96a1;
}

#time-slot-btn.btn.full-day-secondary:hover {
  background-color: #cccccc;
  border: 2px solid #6c757d;
  color: black;
}

#time-slot-btn.btn.full-day-secondary:active {
  background-color: #cccccc;
  border: 2px solid #6c757d;
  color: black;
}

#time-slot-btn.btn.full-day-secondary:focus {
  background-color: white;
  border: 2px solid #6c757d;
  color: black;
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 50%);
}
#time-slot-btn.red-btn.btn.btn-outline-secondary {
  background-color: #cccccc;
  color: black;
}

#time-slot-btn.grey-btn.btn.btn-outline-secondary {
  background-color: $concordRed;
  color: white;
}

#time-slot-btn.red-btn.btn.btn-outline-secondary:hover {
  background-color: #6c757d;
  color: white;
}

#time-slot-btn.red-btn.btn.btn-outline-secondary:focus {
  background-color: #c2c3c7;
  color: black;
  outline: none;
}

#time-slot-btn.grey-btn.btn.btn-outline-secondary:hover {
  background-color: #d32328;
  color: white;
}

#time-slot-btn.grey-btn.btn.btn-outline-secondary:hover {
  background-color: #d32328;
  color: white;
  outline: none;
}