@import '../base/typography';

.App {
  color: #000000;
  font-family: 'Calibre';
  font-size: large;
  margin: auto;
  padding: 1em;
  justify-content: center;  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.footer-container {
  padding-top: 25px;
  height: 50px;
  position: relative;
  bottom: 0;
  text-align: right;
  margin-right: 3%;
  font-size: 12px;
}

a.nav-item.nav-link {
  color: black;
}


