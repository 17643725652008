@import "./variables.module";

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($keys...) {
  @each $breakpoint in $keys {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
      $query: if(type-of($raw-query) == "string", unquote($raw-query), inspect($raw-query));

      @media #{$query} {
        @content;
      }
    } @else {
      @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
    }
  }
}
