@import '../abstracts/variables.module';
@import '../base/typography';

.card-box {
    background-color: rgba(195, 197, 202, 0.502);
    border-radius: 7%;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 470px;
    transform: translate(-50%, -50%);
    padding-top: 3%;
    padding-bottom: 5%;
    padding-right: 2%;
    padding-left: 2%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

@media only screen and (max-width: 500px) {
    .card-box{
        width: 95%;
    }
    
  }

/* In AdminRequestTable && EditWorkstationView.js && SignInCard.js */
.container {
    flex: auto;
    flex-direction: row;
    align-items: center;
}

/* In SignInCard.js && SignInButton.js */
.sign-in-btn {
    position: relative;
    margin: 7% auto;
    bottom: 15%;
    left: 50%;
    width: 70%;
    height: 80px;
    transform: translate(-50%, -10%);
    text-align: center;
    border-radius: 15px;
    font-family: 'CalibreMedium';
    font-size: 1.5pc;
    color: white;
    background-color: $concordRed;
    border: none;
  }

.sign-in-logo-div {
    position: relative;
    left: 50%;
    transform: translate(-50%, -10%);
    top: 15%;
    width: 100%;
    text-align: center;
}

.card-box-text {
    font-family: 'CalibreMedium';
    font-size: 2pc;
    text-align: center;
    margin: 7% auto;
}

.sign-in-logo-image {
    margin: 10% auto;
    height: auto;
    width: auto;
    max-height: 50px;
    max-width: 155px;
  }

 