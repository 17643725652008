/*Styles for hamburger menu in nav bar*/
@import '../abstracts/variables.module';

.hamburger-dropdown {
  position: relative;
  display: flex;
  margin-left: auto; 
  margin-right: 0;  
  flex-direction: column;
  padding-top:10px;
}

.dropdown-icon {
  position: relative;
  padding-right: 25px;
  top:-50px;
}
  
.dropdown-content {
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 100;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 6px 6px;
  list-style-type: none;
  font-weight: 500;
}

.dropdown-content li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}
  
.dropdown-content li:hover {
  background-color: #b5b6ba;
}

.dropdown-content li.sign-out:hover {
  background-color: $concordRed;
  color: #f9f9f9;
}
  
.dropdown-content li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}
  
.dropdown-content li.divider{
  padding: 0;
  border-bottom: 1px solid #cccccc;
}
